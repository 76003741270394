import React from "react"

import styles from "../../styles/main.module.css"

export default function MeditationExperience() {
    const text = {
        "Context": [
            "Over this last summer I found some interest in myself for Buddhism, so I decided to try it out. I made some progress but ended up stopping around September since I unfortunately did not do a good job of turning it into a recurring habit, and haven't really tried to meditate since then. Right now, being in the process of pushing various aspects of Fractal to launch I've regretted quitting meditation since it helped calm and focus my mind—something which I could use in the present. Partly as notes for myself and also whoever reads this, I want to recount some of the things I learned from my excursion into meditation so that this time I can hopefully make more progress and establish the habit for good.",
            "Keep in mind that over this summer I meditated around once a day for fifteen to thirty minutes (plus one to two hour hikes, though those were more discursive thinking in all honesty), so I'm nowhere near exhausting the possibilities offered by the practice. Moreover, some of the advice here may be different for you (or my future self) depending on your situation. Try and think critically about what will best for you, and importantly, try things out. That's the only way to be sure. Moreover, I will meander quite a bit since I’m writing as I’m thinking. Bear with me.",
        ],
        "Why Meditate": [
            "Meditation has two aspects. I think these cover most of meditation that I know of, but they are primarily what I do.",
            "One is to relax, calm, and gain the ability to focus the mind. The mind is always chattering and full of excitation (a common metaphor is that of a gushing waterfall). I often am carried away by distraction, emotional fits, internal debates, whatever. This stops you from doing what you want since you're basically out of your own control, blocking you from living a life well-lived (or at least making it harder, it's like trying to balance on a tightrope when people on opposite sides are playing catch). It also induces suffering-if you anchor your happiness to something ephemeral (and both thoughts and the state of the outside world are ephemeral). We want to set the mind to a calm, controlled state where it can be used for something good to maximum efficacy (for example, erasing suffering and knowing the world as it is—the overarching nominal goal of the Buddhist path).",
            "The other aspect is to use the more calm and controlled mind to actually try and gain some insight. This sounds familiar. It's nothing new to anyone—if you want to intelligently figure something out you think about it (duh). That said, because of the excitation of the mind I've found it hard to think very deeply for very long periods of time, and I'm sure a lot of us have as well. I remember when I was a kid I could go on for hours thinking about the same thing (namely legos or some game), and my concentration would be fairly high granularity. In sports I also had this ability (you kind of have to). You'd think growing up I would have more or less monotonically improved, but it wasn't so. " + 
                "Ever since I've had a smartphone and laptop I've been degrading in as many ways as I've been improving (for example, maybe I can think with more complexity, but for less time), so it's really been a mixed bag. Add to that our consumerist information-oriented society and you'll see we really are hindered in a big way from having insight. By focusing on the previous aspect I mentioned, you open the door to good, deep insight. For a Buddhist this insight is usually something regarding seeing the world as it really is (which erases suffering), but it can be used for a variety of other noble tasks.",
            "So you can think of the first aspect as mental exercise, and then the second aspect as using your able mind to yield great insight. It's like training your body for a race and then racing (though, without the closed-system, competitive aspects of the analogy). The Buddha presents another, perhaps more fun, parable which illustrates this point. He speaks of a king in ancient India whose son was behaving irresponsibly-partying, drinking, and eschewing his responsibilities. To force the prince to grow up, the king sent him to a faraway province to act as a local ruler, but the plan didn't work out as intended since the son just kept on living irresponsibly wherever he went. Irritated, the king summoned one of his wisest ministers and ordered him to go to the prince and set him on the right path. " +
                "The minister, however, explained that to do so he would need a bodyguard and some troops, since otherwise the prince would just order him beheaded. With the prince being royalty, and the minister not, there would be nothing he could do to stop the prince. Understanding the minister's situation, the king gave him the command of a small troop and one burly bodyguard. The minister, thankful for the protection, went on his way and traveled for many days and many nights to the prince's faraway province. Once he arrived at the prince's palace, he entered with his bodyguard and requested an audience with the prince. He was allowed to enter, and once in the prince's chambers the pair went to work. The bodyguard approached the prince and grabbed him by the head, forcing him to the ground. " +
                "Holding a sword above his neck, he yelled \"listen to the minister!\" Of course now the king's orders (as delivered by the minister) were much more effective. You can think of the minister as insight (the second aspect of meditation), and the burly bodyguard as the first aspect of meditation. For the insight to work you need to cut through mental excitation.",
            "(One note, which I've omitted, is that lessening excitation must not be equated to growing dullness. The mind must not be dull, just as it must not be excited. There are practices aimed at this problem too, though I think that often the biggest culprit of an inability to focus is excitation more than dullness, which is why it is my primary focus.)",
        ],
        "What I did": [
            "When I was meditating I would say I did between a two to one ratio of aspect one to aspect two and half and half. I actually want to caution you from doing any more than one third goal two. The reason should be fairly clear from before. You can't get much out of the second aspect without the first. Most of my insight, I was not able to hold on to. I remember some of it as feeling transformative, but as soon as I got it I was immediately back to normal, so not particularly transformative in reality. Part of the problem is that much of insight, especially pertaining to Buddhism (primarily Zen which I had read a little about), is non-conceptual (and potentially ineffable). It's a lot like the difference between understanding something you heard, and really \"getting\" it. " +
                "For example, imagine reading about how bad debt is, and then going into debt and suffering for it. Only after experiencing debt do you really get how bad it really is, even if you \"understood\" it before.",
            "Anyways, I’m meandering now, so let’s get to the point. I will outline some of the meditation that I’ve done and later I’ll also talk about some meditation I want to do, or am planning on doing. I’ll also provide some basic reasons and results and “best practices” that I’ve heard or come up with. I’ve been influenced recently by B Alan Wallace, and in the past by Alan Watts (regarding Zen).",
            "For meditation of the first aspect an important dimension along which meditation varies is the target of focus. Usually, the goal is to find something to focus on single-pointedly and practice doing so for some set of time. A common focus is breath. This ties in closely with mindfulness. The goal of mindfulness is to “bear in mind” (not forget) what one is being mindful of. That in turn requires you to focus on the object one is being mindful of.  If you are not focused on your object of mindfulness, you “forget” it (even if for a short while). Thus mindfulness and focus are in a sense not independent and when I say mindfulness or focus I really mean the whole system which arises.",
            "Objects of focus/mindfulness that I’ve tried include breath, thoughts, my visual field, my auditory field, my sensorial field, objects in my vision, geometric objects in my mind, and my cardiovascular processes (a “field” as well, though only at the end). I usually have done this sitting, but for a couple laying down can work. Informally, I have also focused on maintaining a certain posture, though this was by accident. ",
            "You’ll note that I talk about a few sorts of “fields.” A field is like a collection of objects, but separate from the objects in the collection. You can be single-pointedly mindful of a field. Being focused on a field is not the same as being focused on each of the components of  it. For example, say that in my visual field there are people walking. Being mindful of the crowd is different from being mindful at a lesser extent of each of the people. I’m not entirely sure it is possible to be fully mindful of more than one person at a time (at least I cannot).",
        ],
        "Mindfulness of Breathing": [
            "Mindfulness of breathing is both a classic and really, really powerful. It’s especially useful if you are angry or anxious, since the breathing tends to calm you down. The calming aspect acts to prepare you for the actual meditation, in which you are focused deeply on your breathing. The Buddha tells us to be aware of the breathing, then to be aware of the body breathing. This can mean a couple things. I interpreted it to mean feeling my entire body as I was breathing, insofar as it interacted for the breath. For example, I’d be aware of the field of influence of my breath, including my diaphragm, chest, belly, nose, and anything accompanying that. ",
            "It’s hard to concentrate on the breath for long. It can help to count—with counts I recently made around four or five cycles of uninterrupted mindfulness (meditating for the first time in a month), while without counts I couldn’t get past two cycles easily (each cycle being around three or four). As I was counting I also visualized the numbers to avoid seeing other images, or imagined a balloon inflating and deflating. I realize this is not purely being mindful of breath, but it is a step in the right direction. I recommend closing your eyes for mindfulness of breathing because you want to focus primarily on the breath, and then expand into the body. Visual data will take a toll on the granularity of your focus.",
            "I enjoyed mindfulness of breathing, though it was my second least used technique. I found it harder than mindfulness of thoughts, though in some situations it was easier (for example when I was angry or anxious). ",
            "Mindfulness of breathing is a real workhorse. That’s because it’s orthogonal to anything you’ll concentrate on in normal day-to-day life. It has nothing to do with deep work (say programming, math, writing, engineering, etcetera) and nothing to do with the endless philosophizing and debating we always do. It’s not artistic and it’s not rigorous. It’s not really anything. Because of that, when you focus on it you are less liable to slip into focusing on the wrong thing. For example, while engaging in mindfulness of thoughts I would often lapse from being aware of a thought and observing it, to actually being focusing on its object. I would then continue to deeply think about the object for a long time (say some logical argument, or derivation about the world). ",
            "Another advantage is that the breaths interrupt rumination, so each cycle you’ll get accustomed to noticing if you have lapsed from your focus on the breath. It’s harder to be lost for long so you can get more deep thought in. Also recall that I mentioned the numbers. It’s easy to create an object of focus of higher complexity on top of breathing onto which you can progress after breathing (or start with before, if it’s easier). For example, pick any sequence and then just compute it by breath. Perhaps this is easier for you than focusing on the breath itself. Great, now you have a tool to hone focus before you start aiming your focus on breath. If it’s harder, also great, now you have a potential object to try after you feel you have some mastery of breath. You can also use the breath to explore other internal senses like your heart and blood flow, so there’s a lot to explore which flows naturally from breathing. " +
                "There are schools which say you should stick with one meditation practice, and it is true that switching often can be detrimental, but switching a few times can have advantages—it depends on the case.",
            "With all that said, I found it more mentally tiring than field awareness. I hypothesize that we are all more used to field awareness due to a couple factors. For one, nature encourages field awareness of the senses since predators could come from any angle. For another, social media usage and the internet encourage field awareness since you often have various tabs open on various tasks you are doing at the “same” time. When you are multitasking, from the first person perspective it really feels like you are doing most actions mindlessly (by instinct) since you’ve trained it so much, and instead are focusing on the field of tabs and tasks at hand on your laptop. There is usually at most one task you are really focused on, but in the absence of one “new” task (say reading an interesting paper) which requires your deep attention, you are usually concentrated on the field itself—at least, this is my experience.",
            "In the future I’d like to focus more on mindfulness of breathing, and I think it’s best to do it when you are sitting comfortably, but not necessarily lying down. Walking or standing I do not recommend.",
        ],
        "Mindfulness of Thoughts": [
            "Mindfulness of thoughts was probably my second most used technique. I’d already been doing phony mindfulness of thoughts for a while, but never real mindfulness until maybe June. Mindfulness of thoughts is simple: you nonjudgmentally, are aware of your thoughts as they arise, and then you let them do whatever they do and simply observe. You do not try to stop your thoughts nor to continue them.",
            "(Regarding the phony mindfulness, basically, in the past to develop discipline, in the past I established a system of thinking about my thoughts in the near term after. Unfortunately, this was not really mindfulness. It turned more into an obsessive compulsive super-ego as I progressed and I had to dismantle this system entirely as it was extremely detrimental. You could describe it as precisely anti-Zen, an instance of the mind trying to grasp itself. It was a mistake I made based on my callback/process model of the mind from around a year ago.)",
            "Mindfulness of thoughts is extremely powerful, but a bit slippery. Depending on your emotional mood you can slip into one of a few different subtle traps. The most basic trap is becoming focused on the object of a particularly interesting or alluring thought. This happens constantly, but once you start to get the grasp of how to stop you stop instantly, unlike in mindfulness of breathing where I found my progress to be more linear. It’s the most common trap, but easy to fix. " +
                "Another trap I’ve fallen into based on my phony mindfulness of the past is the trap of thinking about your thinking. You will fall into the first trap and then catch yourself, starting a thought about the thought that you just fell into and the thoughts you have been thinking. You’re being “mindful,” but only of a memory of past thoughts, not of your current thinking. There are probably more levels of recursion you can reach and other traps you can fall into, but these were the two main ones for me. " +
                "The second one was especially hard to avoid once I got interested in categorizing the processes of my mind, and it “kidnapped” my awareness for long periods at a time.",
            "To fight the recursion I think Zen helps (you need to take the Taoist/Zen attitude to thusness). Interestingly, mindfulness of thoughts for me was merged with insight. By being mindful of thoughts and then reflecting on your mindfulness, you begin to see reality as it is. Combined with sensorial mindfulness it helped me understand nondualism on a non-conceptual level. Once I got thinking along these lines, I began to lapse into thoughts whose subject was the seed of some insight for reality as it is, based on observation of previous mental events, which is how I merged them (by accident). With all that said, mindfulness of thoughts was not the primary workhorse I used for gaining a non-conceptual understanding of nondualism (the world as it is), it was more of a secondary workhorse.",
            "Mindfulness of thoughts is useful in part because it helps you see early on just how chaotic your mind is from a vantage point. In mindfulness of breathing you will be interrupted (recall the tightrope analogy), but not “observe” the actual flow of thought. Mindfulness of thought entails actually observing it nonjudgmentally. In this sense it helps you understand who you are and what’s going on in your mind. Moreover, you grasp intuitively how ephemeral thoughts are. Anger cannot last more than a second or two in the absence of stimuli. Same for lust, ill will, anxiety, sadness, and more. If you simply observe its creation, you will see it just as soon observe its evaporation. It can only live on if you focus on it and start generating discursive thoughts in line with the feeling. " +
                "Noting that stimuli is usually more like a button and less like a switch, anger (for example) has no reason to exist for longer than a couple seconds in almost all situations. Instead, we are usually prolonging anger by re-creating again and again and again.",
            "I did this with my eyes open for the most part, but it’s probably possible to do it with your eyes closed. I recommend sitting comfortably or laying down, though probably sitting comfortably.",
            "In the future I’d like to also try awareness of awareness, which seems like it’s related. I can’t really say since I haven't done it. I tried once but I couldn’t tell if I was succeeding. It seems pretty hard.",
        ],
        "Visual, Auditory and/or Sensorial Fields (Including Heart, Blood Flow and Posture)": [
            "This was the easiest form of mindfulness meditation for me, and also my most common. The idea is simple: stop discursive thinking by focusing purely on your senses, or one aspect of your senses (I certainly never tried all of them at once). Visual and auditory are the easiest, and I focused on visual. It’s hard to explain what you are focusing on specifically, but basically I spent my time being aware of what was going on around me by observing within my visual field. That was all.",
            "This is a very easy introduction to meditation and is potent at stopping discursive thought since it’s stimulus based. It’s also as potent as mindfulness of breath to stop anxiety or other negative feelings. After simply being aware for a few minutes I’d find out that I’d started smiling. The best way to describe how I felt while smiling was as a feeling of “knowing.” Not quite “I know something you don’t” but more of just “aha, I know,” but without the suddenness (though there is suddenness in the occurrence of knowing, there is not in the “knowing” feeling). Aha moments and the knowing feeling are good, so it’s natural to smile.",
            "As I mentioned that it’s stimulus based, let me emphasize this point one more time. It’s stimulus based! This is a beginner advantage but also a potential con. The advantage is that it’s easier to make progress unless you somehow never pay attention to your surroundings, which I’ve found to surprisingly be more and more the case for me in real life. The con is that it might be less transferable than mindfulness of breathing. With that said I cannot be entirely sure since my use of this technique was so asymmetrically greater in quantity than that of mindfulness of breathing. One small note, though, of which I am more confident in, is that it is more transferable than single-object sensorial focus, in which you focus on a single object in your field of view (or whatever sensorial field you pick).",
            "I liked this style of meditation for another reason as well: it’s a potent segway into insight about the world itself. As I’ve mentioned I usually have been living (since I’ve been at home) paying no attention to the world around me. This is because I know my home so well that I could close my eyes and still get from the kitchen to my room and back, including stairs. I simply do not need to be focused on the physical world around me, and thus I’m usually lost in thought. When I started doing visual field meditation I suddenly began to see aspects of my environment which I had not seen before, or not seen in years—potentially more than a decade, literally. I perceived the details in pictures we have, the cracked hide-like patterns in a specific lamp we have near the main entrance, the shapes of the trees. Especially the shapes of the trees. " +
                "Trees everywhere have so much shape. A ton of it. If you just perceive a blob of leaves on a trunk you are missing a lot. I started calling a tree Merlin for example, because of its hanging boughs like Merlin’s beard. Of course this came after the non-judgemental awareness I first evoked in the meditation, though at times it interrupted me.",
            "By observing the shapes of the trees I began to understand how a dual world was created by slicing up a non-dual one (choosing what is x and what is not x). This is fundamental to the Buddhist understanding of the world, but to really “get” it you need to have a non-conceptual understanding, not just a conceptual one. I progressed in this understanding by mentally merging items that were categorized differently up the categorization tree until there was only one thing and then I perceived thusness. The first stretch involved merging objects in my view farther away, for whom I could easily perceive their entire shape. Next I merged them with the backdrop by removing perspective. " +
                "Lastly I removed perspective progressively closer to my field of vision until everything was covered. The first step was easy, the second was not so easy, and the last one was fairly difficult. Observing objects that are close to platonic, like a house, makes this task immensely more difficult. I never succeeded in perceiving thusness in my house, only in the garden. The reason is that houses, no matter how complicated, are almost always geometric prisms. It’s extremely hard to not perceive difference when the edge is, as far as you can tell, perfectly straight. In that sense prisms are the prisons of the mind. " +
                "It’s easier to merge aspects of the physical world when looking at bushes and small animals running about, especially if there is no clear delineation where the bushes end and trees behind them start.",
            "Focusing on visual perception helped me harness the oceanic feeling as described by Freud. This is certainly not enlightenment, but it is the earliest taste of it, at least regarding nondualism. Once you have the oceanic feeling you realize how little importance can reasonably be attributed to your fears, worries, and to some extent dreams in the grander scheme of things.",
            "There are a couple subtle traps in visual field awareness, but they are minor relative to mindfulness of thought I think. The main risk is that in focusing on the visual field you may accidentally focus on an object in the visual field. Similarly, your focus might grow to include auditory and tactile awareness (which also has benefits, and I’ll talk about those soon). Both of these cases (focusing on an object, and focusing on general sensory awareness) can be good objects of mindfulness in their own right, but if your goal is to be able to focus on what you decide to focus (and this should be a part of your goal) then missing the target is a mistake. I call these traps subtle because sometimes the difference between a visual object and the visual field can become blurred. " +
                "Moreover, it can be exciting to look at an object instead of a visual field, and just as in the case of mindfulness of thought where you mess up by focusing on the object of the thought, in the visual field case you can start focusing on the object in the field. (There are a lot of similarities between mindfulness of thought and mindfulness of the visual field, maybe I’ll start calling the former “mindfulness of the mental field” at some point to include mental activity which is not “thought” per say.) Because we’ve basically been trained to focus on objects and not the entire visual field, it can be easy to slip into looking at objects one a time.",
            "Regarding general sensorial awareness as an expansion from visual field awareness, I tried it with a primary presence in visual-auditory and a secondary presence in the body. (You are aware of what is behind you with sound, what is in front of you with vision, and the state of your body with feeling.) I can’t really explain what I mean by this, but you can reach a state such that you are extremely still and mentally “empty” and yet completely aware and read to spring up immediately while still relaxed physically. It’s like being ready to jump up and catch a ball as soon as it is thrown, but with no thought and no situation creating the state of mind—simply your own prior will which initiated it and your focus which maintains it. " +
                "In this state you know that you have perfect control over your limbs and you are alert to what is going on around you. It’s not like typing (i.e. what I’m doing right now) where I imagine what I want to see written and my hands move by themselves to see to it that my thoughts are written. It’s like I’m aware and in control of my movements exactly, though I am of course not moving. There is a feeling of potential energy. I think this is close to the healthy human default mode of functioning. " + 
                "It feels like it should fit very reasonably into nature. It is also non-conceptual.",
            "Auditory field mindfulness I only tried once. I ended up giving up and doing both visual and auditory halfway through because I would hear people talk and start focusing on the words they were saying. It’s immensely difficult to listen to words and just hear sound. However, if you are to focus on the auditory field itself, you must not be listening to the words themselves and understanding their meanings, since that would entail focusing on the speech and language, not on the field. It’s easier if you just have something unintelligible to listen to, like birds. (We have a bird feeder which is why I have all this stuff about birds and squirrels.) I can’t say whether I recommend auditory mindfulness, but I’m sure for some people it’s just like visual mindfulness is for me.",
            "As for mindfulness of cardiovascular processes, I did this a couple times before sleeping. I was laying down just feeling my heart beat and feeling the flow of blood to my extremities. It didn’t help me much with anything but it was relaxing. It also was not dull, though you might think so. I could not fall asleep until I stopped focusing. It was strangely a comforting feeling. Something like checking whether the world machine is working, and seeing that it’s working, all is good, nothing to fear. Kind of like being a really little kid and coming in from the rain and sitting next to the heater and thinking about how tough the house is to withstand the storm. The roof and walls are working, all is good, nothing to fear. That said, I could only feel this after I’d basically exhausted my search for a feeling of blood flow. When you are perceiving your inner processes you obviously don’t feel almost anything at the beginning. " +
                "You have to really focus on one element, say the heart, and then progress radially outwards feeling, mentally, one point of feeling at a time. In the end you can feel more than a single point at a time, but it may be fainter if you cast a wider net. This is why I say it’s also a field awareness, but only at the end (you need to search out enough space to be able to perceive it as a field). It’s easiest after strenuous exercise. I do not have much of an opinion on this form of mindfulness, other than it can be relaxing and unique. It doesn’t seem to me to be particularly useful for segwaying into insight at all.",
            "Regarding focusing on posture: this is an accidental discovery. If you find it uncomfortable to sit in the lotus position cross-legged for a medium to long period, you can also simply focus on keeping the right posture as you inevitably sink into bad posture. It forces you to be alert and mindful of your form and the feeling it entails. I don’t consider my accidental spending of half a mindfulness of breath session of maintaining the same posture “meditation” but I can see there being value in this. If you can’t hold the cross legged position in good form without leaning forwards or backwards for half an hour, maybe try it. Just maybe. (Yea probably not.)",
        ],
        "Single-Object Focus (Primarily Visual)": [
            "I used this surprisingly much. It’s easy to fall into while concentrating on the visual field. You’ll be looking at your visual field and see, say a bird, and start looking at the bird and whatever it’s doing. Obviously I’m not like instantly distracted by a bird—I’m not a toddler—but I’ll notice it, my eyes will twitch, then I’ll be back to the field. Back to the field, usually.",
            "On one occasion I thought “what if I completely derail this meditation session and instead single pointedly focus on the bird.” It would be an interestingly different practice which I could compare with the field experience. Moreover, if the bird flew too far away to see I could just return to field awareness. On that and a couple later occasions, this is what I did. Once it was a bird, once a leaf on a low-hanging twig at the end of a branch, and once on a very big fly.",
            "On the first occasion, I basically stared at this bird for six or seven minutes straight. I also listened for sounds of its movement. It was amazing how it never moved out of my field of vision. On the latter occasions I lasted a bit less, but I felt that the quality of my focus was surprisingly good on each of these. It’s easy because we are used to looking at beings in the physical world doing things. If you’ve ever played a sport you spend half your time observing, and the other half acting—obviously, you need to be aware of what the hell is going on to not suck.",
            "I do not recommend this particular method of focus for mindfulness meditation unless you are under a very specific set of circumstances. Since the goal of mindfulness meditation is to prepare you for insight, concentrating on physical objects isn’t particularly helpful. While I believe focus/attention ability is transferable, there are realms where it is harder to transfer between, and/or you’ll need to train a different type of focus from scratch (though you’ll progress much faster if you have prior training in some other type of focus). " + 
                "I hypothesize that external vs. internal is one such separation of realms (as I’ll rather non-rigorously call them). As I’ve said, focusing on external objects or fields was much easier for me to focus on than on my thoughts or breath. Actually, breath might be an interesting middle ground, but still. I especially see a rift between the duo of audio-visual perception and internal feeling plus thoughts. I have not tried the other three senses so I cannot say too much about their relation in this regard.",
            "Anyways, the set of circumstances in which you’d want to do single-pointed focus on individual sensorial objects are if you need to train focus for some sort of athletic ability where you need to be able to focus on a single task or physical object. For example, if you need to shoot three pointers, this might help. If you were to find yourself in a sixteenth century samurai duel, you’d also want this (though its hard to say since things move so fast). Since most of us are neither professional athletes nor sixteenth century samurai, I think there are better meditation practices to engage in. If you do decide to go for focus on a single sensorial object (of the audi-visual fields) I encourage you to sit upright comfortably.",
            "Also an addendum: one advantage I’ve forgotten to mention of this technique, is that it’s very easy to tell when you are doing badly, very easy to return on track, and very easy to know when to stop (namely the thing you are looking at hides behind terrain and now you can’t see it). This is important because it lets you immerse yourself in your task of focusing. If you are wondering why it’s easy to tell when you are doing wrong or why you can return on track easily, just try it out. Roughly speaking, for the former your vision will lose focus. For the latter, the fact that this is a form of stimulus means that you have years of practice to back you up (the only difficulty is focusing on only one stimulus).",
            "Another addendum: this meditation may be good if you are feeling dullness or laxity instead of excitation. Since it is stimulus based you can use the coarse excitation of the visual object to immediately focus and then practice the maintenance of a continuous flow of focus.",
        ], 
        "Mental Visions (Geometric and Platonic Objects, Sensorial Re-imagining)": [
            "This was my least used technique so I can’t say too much. The idea is to visualize a platonic object and then manipulate it in your mind to high precision. As you get better you can increase the complexity of the object you are visualizing. I visualized a tetrahedron and later an octahedron. I also tried a table with a pair of chairs. I only did this twice so I did not find it extremely fruitful. I imagine that it would be better if I had already honed my focus to a sufficient degree where I could try more complex objects and start to find patterns.",
            "Another option, which I did not consider doing, is to visualize non-platonic things that you’ve seen in real life or can imagine. Artists probably excel at this. For example, I could look at my bookshelf and then close my eyes and go through all the books, pull them out, read the title, but them back in, and so on. This sounds like a difficult task for me but I think it may be very fruitful once I am able to hone my calm and focus sufficiently to start working on this without getting frustrated. I want to make this one of my primary methods once I feel I have mastery of mindfulness of breathing and/or thoughts or sensorial fields. ",
            "I would also like to try it for music and sound. Can I recreate an entire song at a granular level in my mind? Can I compose music in my mind? Doesn’t sound like meditation per say but I think it can help me focus in areas that interest me, which in turn can be helpful for future meditation, since I’m working under the assumption here that some level of focus/attention ability is transferable between different tasks.",
        ],
        "Insight": [
            "I can’t say much for insight since it was non-conceptual. I looked at the world and tried to forget what I’d learned. I tried to forget language, to forget shapes, to forget meaning, to forget logic. I just observed the world as it was and understood, that that was how it was and not any other way. It could not be explained in language, had no form, had no meaning, and was neither rational nor irrational. It did not exist nor did it not exist. It just was. I did this while focusing primarily on mindfulness of sensorial field data and (less often) thought.",
            "Insight should probably come after you’ve gotten enough mindfulness and focus training to be able to push the insight to its conclusion. The insight I gained was instant and vanished just as it had arisen. I could not really explain it other than by saying things like “language is not real” which is clearly bullshit and a misleading explanation. I do think that non-dualistic insight is ineffable, but you can do a very good job of using language to explain both how to get there and what it feels like, though it may be more artistic than precise. Because I feel compelled to create systems to describe things I tried this and did not succeed (obviously). I remember when I had the insight only somewhat vaguely now and I know that I cannot regain it easily. If I could regain it it would be much easier to explain. Thus I will focus primarily on mindfulness and focus on the future and you should too. Insight can come after. There is no rush.",
            "For insight Alan Watts’ The Way of Zen was immensely helpful for being able to grasp the feeling of no-self and non-dualism. I can’t vouch for other aspects of Alan’s work but that book in particular was useful. It’s only drawback is that it pushes you into early insight. Basically, it helps you spike, but you’ll inevitably fall back down like I did. Thus I recommend probably a good one to four months of mindfulness training before going for any insight. You’ll find yourself having some insight by accident and this is good, don’t try to stop it. However, don’t make insight your primary focus until you can be mindful continuously for at least half an hour and can meditate comfortably for at least an hour.",
        ],
        "Final Thoughts": [
            "I found that focusing on quality over quantity was effective. When I started out I thought to myself, “I’ll do an hour or two hours,” only to realize that I couldn’t go fifteen minutes without getting very restless. Now I can comfortably go twenty minutes, after which I’ll get distracted to a large enough degree to physically move, in all likelihood. If I’m very dedicated I believe I could go for forty minutes or so now, but they would be low quality minutes, spent mostly ruminating. I think it’s crucial for beginners to do small chunks no less than five minutes, but no more than twenty. Ten to fifteen is a good starting point. You can concentrate on making your focus very deep and constant. To get sufficient volume to improve at a respectable pace you can simply do more sessions with breaks in between where you think about how your previous session went and what to do accordingly for the next one.",
            "Anyways, I encourage everyone to try meditation. You can’t really say much about it until you’ve tried it since it’s very different from our default mode. Moreover, its effects are overwhelmingly positive so for a good week or two weeks it’s worth working on.",
            "I hope to write something more concise and focused once I can synthesize my experiences again in a month or so of more meditation. I also apologize if anything makes little to no sense. I’ve written this directly as I thought of it with no edits. I’m trying to get out my thoughts first before I start polishing them. Volume first, quality later. In some things, as in writing a personal blog, I think this is the right approach.",
        ]
    };

    return (
      <React.Fragment>
        <h2>A Brief Excursion</h2>
        {Object.keys(text).map(key => (
            <div>
                <h3>{key}</h3>
                {text[key].map(ptext => (
                    <p>{ptext}</p>)
                )}
            </div>)
        )}
        <div className={styles.phone_home}>
          <a href="/">Click me to return home.</a>
        </div>
      </React.Fragment>
    )
}
